import { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Section from "../Section";
import Auth from "./Auth";
import AuthFooter from "./AuthFooter";
import CustomLink from "../../util/CustomLink";
import AuthNRSHeader from "./AuthNRSHeader";

function AuthSection({
  bgcolor,
  size,
  authType,
  providers,
  afterAuthPath,
  isBusiness,
  hideFooter,
  handleCloseModal,
}) {
  const theme = useTheme();
  const isSmOrSmaller = useMediaQuery(theme.breakpoints.down("md"));
  // const [serverError, setServerError] = useState(false);
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [shouldVerify, setShouldVerify] = useState(false);
  const [pending, setPending] = useState(false);
  const [type, setType] = useState(authType);

  // Options by auth type
  const optionsByType = {
    signup: {
      // Top Title
      title: isBusiness ? "Create business account" : "Create free account",
      // Button text
      buttonAction: "Sign up",
      // showFooter: accessKey && accessKeyIsValid,
      showFooter: hideFooter ? false : true,
      signinText: "Already have an account?",
      signinAction: "Sign in",
      signinPath: isBusiness ? "/business/auth/signin" : "/auth/signin",
      // showAgreement: accessKey && accessKeyIsValid,
      showAgreement: true,
      termsPath: "/legal/terms-of-service",
      privacyPolicyPath: "/legal/privacy-policy",
    },
    signin: {
      title: "Welcome back",
      buttonAction: "Sign in",
      showFooter: true,
      signupText: "Don't have an account?",
      signupAction: "Make one!",
      signupPath: isBusiness ? "/business/auth/signup" : "/auth/signup",
      // signupAction: "Don't have an account? Join the waitlist!",
      // signupPath: "/",
      forgotPassAction: "Forgot Password?",
      forgotPassPath: "/auth/forgotpass",
    },
    forgotpass: {
      title: "Get a new password",
      buttonAction: "Reset password",
      showFooter: true,
      signinText: "Remember it after all?",
      signinAction: "Sign in",
      signinPath: isBusiness ? "/business/auth/signin" : "/auth/signin",
    },
    changepass: {
      title: "Choose a new password",
      buttonAction: "Change password",
    },
  };

  useEffect(() => {
    if (authType !== "signup") setSignupSuccess(false);
    setType(optionsByType[authType] ? authType : "signup");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authType]);

  // Get options object for current auth type
  const options = optionsByType[type];

  return (
    <Section bgcolor={bgcolor} size={size}>
      <Container
        maxWidth="sm"
        disableGutters={!isSmOrSmaller}
        sx={{
          zIndex: 10,
          pt: 2,
          px: 0,
          // my: "40px",
          minHeight: isSmOrSmaller
            ? null
            : {
                xs: "calc(100vh - 136px - 80px)",
                lg: "calc(100vh - 72px - 80px - 64px)",
              },
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        {/* NRS Header */}
        {(!isSmOrSmaller || handleCloseModal) && <AuthNRSHeader />}

        {/* Title */}
        {/* {!signupSuccess && (
          <Typography
            variant="h2"
            sx={{
              marginBottom: 2,
              fontWeight: "400",
              fontSize: "1.4rem",
              textAlign: { xs: "center", md: "start" },
              color: "rgb(244 244 244)",
            }}
          >
            {options.title}
          </Typography>
        )} */}

        {/* Pending spinner */}
        {((signupSuccess && pending) || pending) && (
          <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
            <CircularProgress />
          </Box>
        )}

        {/* {shouldVerify && (
          <Button onClick={() => setShouldVerify(false)}>Login</Button>
        )} */}
        {/* <SectionHeader
          title={options.title} // "Welcome back" etc.
          subtitle=""
          size={4}
          textAlign="center"
          titleFontWeight="bold"
        /> */}

        {/* Signup/signin options */}
        <Auth
          type={type}
          setType={setType}
          buttonAction={options?.buttonAction}
          providers={providers}
          afterAuthPath={afterAuthPath}
          key={type}
          signupSuccess={signupSuccess}
          setSignupSuccess={setSignupSuccess}
          shouldVerify={shouldVerify}
          setShouldVerify={setShouldVerify}
          pending={pending}
          setPending={setPending}
          handleCloseModal={handleCloseModal}
        />
        {/* Terms & Conditions */}
        {(type === "signup" || type === "signin") &&
          !signupSuccess &&
          !shouldVerify && (
            <Box
              sx={{
                mt: 2,
                mb: options?.showFooter ? 2 : 8,
                fontSize: ".8rem",
                color: "accents.light",
              }}
            >
              By signing {type === "signup" ? "up" : "in"}, you are agreeing to
              our{" "}
              <CustomLink
                href="/legal/terms-of-service"
                type="mui"
                target={handleCloseModal ? "_blank" : undefined}
                rel={handleCloseModal ? "noopener noreferrer" : undefined}
              >
                Terms of Service
              </CustomLink>{" "}
              and{" "}
              <CustomLink
                href="/legal/privacy-policy"
                type="mui"
                target={handleCloseModal ? "_blank" : undefined}
                rel={handleCloseModal ? "noopener noreferrer" : undefined}
              >
                Privacy Policy
              </CustomLink>
              .
            </Box>
          )}
        {/* Auth footer */}
        {options?.showFooter && (
          <AuthFooter
            type={type}
            setType={setType}
            signupSuccess={signupSuccess}
            shouldVerify={shouldVerify}
            isSmOrSmaller={isSmOrSmaller}
            handleCloseModal={handleCloseModal}
            {...options}
          />
        )}
      </Container>
    </Section>
  );
}

export default AuthSection;
