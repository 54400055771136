import { useEffect, useRef, useState } from "react";
import { resendEmailToken, submitEmailToken } from "../../api/userManagement";
import {
  Alert,
  Box,
  Button,
  OutlinedInput,
  Snackbar,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "../../util/auth";
import PageLoader from "../PageLoader";
import { logger } from "../../util/logger";
import Link from "next/link";

const AuthEmailValidation = ({
  email,
  onFormAlert,
  pass,
  setPass,
  shouldVerify,
  setShouldVerify,
  setType,
  handleCloseModal,
  setSignupSuccess,
}) => {
  const auth = useAuth();
  const [token, setToken] = useState(["", "", "", "", "", ""]);
  const [pending, setPending] = useState(false);
  const [validationSuccess, setValidationSuccess] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const calledRef = useRef();

  // Triggered when something is pasted into the input fields
  const handlePaste = (e) => {
    onFormAlert(null);
    let data = e.clipboardData.getData("text");
    if (data.length === 6 && /^\d+$/.test(data)) {
      setToken(data.split(""));
    }
  };

  // Handle input changes
  const handleChange = (index) => (e) => {
    onFormAlert(null);
    const newToken = [...token];
    newToken[index] = e.target.value.slice(0, 1);
    setToken(newToken);
    // Move to next input if the current one is filled
    if (index < 5 && e.target.value) {
      document.getElementById(`token-field-${index + 1}`).focus();
    }
  };

  // Handle backspace
  const handleKeyDown = (index) => (e) => {
    onFormAlert(null);
    // If backspace is pressed and the field is empty, move to the previous field
    if (e.key === "Backspace" && index > 0 && !token[index]) {
      e.preventDefault(); // Prevents the default backspace behavior
      const newToken = [...token];
      newToken[index - 1] = ""; // Clear the previous field
      setToken(newToken);
      document.getElementById(`token-field-${index - 1}`).focus();
    }
  };

  // Handle token submission
  const handleSubmitCode = async () => {
    try {
      onFormAlert(null);
      setPending(true);
      await submitEmailToken(email, token.join(""));
      setValidationSuccess(true);
      setSnackbarOpen(true);
      await auth.signin(email, pass);
      handleCloseModal();
    } catch (err) {
      console.error(err);
      if (err.status === 401) {
        setToken(["", "", "", "", "", ""]);
        onFormAlert({
          type: "error",
          message:
            "Expired or invalid code. Please click the link below to resend.",
        });
        setToken(["", "", "", "", "", ""]);
      } else if (err.status === 503) {
        onFormAlert({
          type: "error",
          message:
            "Sorry, our robots have their hands full. 🤖😓 Please try again soon!",
        });
      } else if (err.status === 428) {
        onFormAlert({
          type: "warning",
          message:
            "Verification or login failed. Please attempt login to receive a new code.",
        });
        setToken(["", "", "", "", "", ""]);
      } else {
        onFormAlert({
          type: "error",
          message: "An unexpected error occurred. Please retry.",
        });
      }
    } finally {
      setPending(false);
      setValidationSuccess(false);
      setSnackbarOpen(false);
    }
  };

  // Handle click of resend email link
  const handleResendEmail = async () => {
    try {
      onFormAlert(null);
      if (!email) {
        onFormAlert({
          type: "error",
          message:
            "An error occurred sending your verification code. Please attempt login to resolve this issue.",
        });
        return;
      }
      await resendEmailToken(email);
      onFormAlert({
        type: "success",
        message: "Sent! Please check your email.",
      });
    } catch (err) {
      console.error(err);
      onFormAlert({
        type: "error",
        message:
          "An error occurred sending your verification code. If you do not see it in your inbox, please retry.",
      });
      setPending(false);
    }
  };

  // Resend the verification email if the user got here after unverified signin attempt (it expires in 5 mins so most likely they need a new one)
  useEffect(() => {
    const bespokeHandleResendEmail = async () => {
      try {
        calledRef.current = true;
        logger("bespokeresendemail callling", email);
        await resendEmailToken(email);
      } catch (err) {
        console.error("bespokeresenderr:", err);
        onFormAlert({
          type: "error",
          message:
            "An error occurred sending your verification code. If you do not see it in your inbox, please retry.",
        });
        setPending(false);
      }
    };
    shouldVerify && email && !calledRef.current && bespokeHandleResendEmail();
  }, [shouldVerify, email, onFormAlert]);

  const handleLoginClick = () => {
    onFormAlert(null);
    setPending(false);
    setPass("");
    setShouldVerify(false);
    if (handleCloseModal) {
      setSignupSuccess(false);
      setType("signin");
    }
  };

  return (
    <>
      {validationSuccess ? (
        <>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={() => setSnackbarOpen(false)}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert onClose={() => setSnackbarOpen(false)} severity="success">
              Your account has been validated. Signing you in...
            </Alert>
          </Snackbar>
          <PageLoader />
        </>
      ) : (
        <>
          <span>
            {!shouldVerify
              ? "Your account was successfully created! Please check your email for a security code and enter it below (expires in 5 minutes):"
              : "A new verification email was sent to your inbox. Please check it for your security code and enter it below (expires in 5 minutes):"}
          </span>
          <Box
            onPaste={handlePaste}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "5px",
              my: "20px",
            }}
          >
            {token.map((digit, index) => (
              <OutlinedInput
                key={index}
                id={`token-field-${index}`}
                type="text"
                maxLength="1"
                value={digit}
                onChange={handleChange(index)}
                onKeyDown={handleKeyDown(index)}
                sx={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "5px",
                  textAlign: "center",
                  "&:focus": {
                    borderColor: "primary",
                  },
                  "& input": {
                    textAlign: "center",
                    padding: "3px",
                  },
                }}
              />
            ))}
          </Box>
          <Box display="flex" justifyContent="center" my="10px">
            <LoadingButton
              variant="contained"
              onClick={handleSubmitCode}
              loading={pending}
            >
              Submit
            </LoadingButton>
          </Box>
          <Box display="flex" justifyContent="space-around">
            <Typography
              onClick={handleResendEmail}
              color="primary"
              sx={{
                my: "10px",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Resend code
            </Typography>
            {handleCloseModal ? (
              <Button
                onClick={handleLoginClick}
                sx={{
                  fontSize: "1rem",
                  fontWeight: "400",
                  textDecoration: "underline",
                  textTransform: "none",
                }}
              >
                Login
              </Button>
            ) : (
              <Link href="/auth/signin">
                <Typography
                  onClick={handleLoginClick}
                  color="primary"
                  sx={{
                    my: "10px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Login
                </Typography>
              </Link>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default AuthEmailValidation;
